
import { defineComponent, ref } from 'vue';
import { PublicKey } from '@solana/web3.js';
import QuestionMark from '@/components/QuestionMark.vue';
import { NFTMintEditionFromMaster, NFTMintMaster } from '@/common/NFTmint';
import useWallet from '@/composables/wallet';
import NotifyWarning from '@/components/notifications/NotifyWarning.vue';
import NotifySuccess from '@/components/notifications/NotifySuccess.vue';
import { IMintResult, INFT } from '@/common/helpers/types';
import LoadingIcon from '@/components/LoadingIcon.vue';
import NFTViewCard from '@/components/NFTViewCard.vue';
import { NFTGet } from '@/common/NFTget';
import useModal from '@/composables/modal';
import ModalWindow from '@/components/ModalWindow.vue';
import ContentTooltipArweave from '@/components/content/tooltip/ContentTooltipArweave.vue';
import useError from '@/composables/error';
import ExplorerLink from '@/components/ExplorerLink.vue';
import StdNotifications from '@/components/StdNotifications.vue';
import { DEFAULTS } from '@/globals';
import axios from 'axios';

export default defineComponent({
  components: {
    StdNotifications,
    ExplorerLink,
    ContentTooltipArweave,
    ModalWindow,
    NFTViewCard,
    LoadingIcon,
    NotifySuccess,
    NotifyWarning,
    QuestionMark,
  },
  setup() {
    const { isConnected, getWallet, getWalletAddress } = useWallet();
    const { error, clearError, setError, tryConvertToPk } = useError();

    const chosenNFTType = ref('master');
    const isLoading = ref<boolean>(false);
    const isMinted = ref<boolean>(false);
    const mintResult = ref<IMintResult | null>(null);
    const newNFT = ref<INFT | null>(null);
    let address;

    const clearPreviousResults = () => {
      //isLoading.value = false;
      mintResult.value = null;
      newNFT.value = null;
      clearError();
    };

    const fetchNewNFT = async () => {
      // this will keep failing, while the network updates, for a while so keep retrying
      isMinted.value = true;
      try {
        [newNFT.value] = await NFTGet({ mint: new PublicKey(mintResult.value!.mint) });
      } catch (e) {
        await fetchNewNFT();
      }
    };

    // --------------------------------------- master
    const text = ref<string | null>(null);
    const maxSupply = 1;

    const mintNewMaster = async () => {
      address = getWalletAddress();

      if (address) {
        isLoading.value = true;
        
        axios.post('https://api.vibrations.fm/create', {
          text: text.value!,
          address: address.toString()
        })
        .then(function (response) {
          clearPreviousResults();
          NFTMintMaster(getWallet() as any, response.data.metadata, maxSupply)
            .then(async (result) => {
              mintResult.value = result as IMintResult;
              console.log(result);
              //isLoading.value = false;
              await fetchNewNFT();
            })
            .catch((e) => {
              setError(e);
              //isLoading.value = false;
            });
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    }

    return {
      DEFAULTS,
      isConnected,
      error,
      chosenNFTType,
      isLoading,
      mintResult,
      newNFT,
      // master
      text,
      maxSupply,
      mintNewMaster
    };
  },
});
