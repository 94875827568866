
import { defineComponent } from 'vue';
import NotifyError from './notifications/NotifyError.vue';
import NotifyInfo from './notifications/NotifyInfo.vue';

export default defineComponent({
  components: { NotifyInfo, NotifyError },
  props: {
    isConnected: Boolean,
    error: String,
    isLoading: Boolean,
    isMinted: Boolean 
  },
  setup() {
    return {};
  },
});
