import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheNavBar = _resolveComponent("TheNavBar")!
  const _component_ConfigPane = _resolveComponent("ConfigPane")!
  const _component_NFTMintForm = _resolveComponent("NFTMintForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TheNavBar),
    _createVNode(_component_ConfigPane),
    _createVNode(_component_NFTMintForm)
  ]))
}