
import { defineComponent, ref } from 'vue';
import { PublicKey } from '@solana/web3.js';
import QuestionMark from '@/components/QuestionMark.vue';
import NotifySuccess from '@/components/notifications/NotifySuccess.vue';
import LoadingIcon from '@/components/LoadingIcon.vue';
import NFTViewCard from '@/components/NFTViewCard.vue';
import ModalWindow from '@/components/ModalWindow.vue';
import useWallet from '@/composables/wallet';
import useError from '@/composables/error';
import { INFT } from '@/common/helpers/types';
import { NFTGet } from '@/common/NFTget';
import { NFTUpdate } from '@/common/NFTupdate';
import useModal from '@/composables/modal';
import ExplorerLink from '@/components/ExplorerLink.vue';
import { objectOneInsideObjectTwo } from '@/common/helpers/util';
import ContentTooltipMetadata from '@/components/content/tooltip/ContentTooltipMetadata.vue';
import StdNotifications from '@/components/StdNotifications.vue';
import { DEFAULTS } from '@/globals';

export default defineComponent({
  components: {
    StdNotifications,
    ContentTooltipMetadata,
    ExplorerLink,
    ModalWindow,
    NFTViewCard,
    LoadingIcon,
    NotifySuccess,
    QuestionMark,
  },
  setup() {
    const { isConnected, getWallet } = useWallet();
    const { error, clearError, setError, tryConvertToPk, tryParseJSON, tryParseMetadataData } =
      useError();

    const isLoading = ref<boolean>(false);
    const txId = ref<string | null>(null);
    const updatedNFT = ref<INFT | null>(null);

    const clearPreviousResults = () => {
      isLoading.value = false;
      txId.value = null;
      updatedNFT.value = null;
      clearError();
    };

    // --------------------------------------- update nft
    const editionMint = ref<string | null>(null);
    const newMetadataData = ref<any>(null);
    const newUpdateAuthority = ref<string | null>(null);
    const primarySaleHappened = ref<boolean | null>(null);

    const fetchUpdatedNFT = async (): Promise<void> => {
      // retry recursively until new attributes confirmed on the network
      try {
        const [fetchedNFT] = await NFTGet({ mint: new PublicKey(editionMint.value!) });
        // if any of the below conditions fail, then we need to fetch again
        if (
          (newMetadataData.value &&
            !objectOneInsideObjectTwo(
              tryParseMetadataData(tryParseJSON(newMetadataData.value)),
              fetchedNFT.metadataOnchain.data
            )) ||
          (newUpdateAuthority.value &&
            newUpdateAuthority.value !== fetchedNFT.metadataOnchain.updateAuthority) ||
          // primary sale can only be set to "true" = the only use case we're checking
          (primarySaleHappened.value &&
            (fetchedNFT.metadataOnchain.primarySaleHappened as any as number) !== 1)
        ) {
          return await fetchUpdatedNFT();
        }

        updatedNFT.value = fetchedNFT;
      } catch (e) {
        await fetchUpdatedNFT();
      }
    };

    const updateNFT = async () => {
      clearPreviousResults();
      isLoading.value = true;

      const parsedJSON = tryParseJSON(newMetadataData.value);
      let parsedMetadata;
      if (parsedJSON) parsedMetadata = tryParseMetadataData(parsedJSON);
      const editionPk = tryConvertToPk(editionMint.value);
      const updatePk = tryConvertToPk(newUpdateAuthority.value);
      if (error.value) {
        return;
      }

      NFTUpdate(
        getWallet() as any,
        editionPk!,
        parsedMetadata as any, // null-undefined conflict
        updatePk as any, // null-undefined conflict
        primarySaleHappened.value as any // null-undefined conflict
      )
        .then(async (result: string) => {
          txId.value = result;
          isLoading.value = false;
          await fetchUpdatedNFT();
        })
        .catch((e) => {
          setError(e);
          isLoading.value = false;
        });
    };

    // --------------------------------------- modals
    const { registerModal, isModalVisible, showModal, hideModal } = useModal();
    registerModal('tooltipMetadata');

    return {
      DEFAULTS,
      isConnected,
      error,
      isLoading,
      txId,
      updatedNFT,
      // update
      editionMint,
      newMetadataData,
      newUpdateAuthority,
      primarySaleHappened,
      updateNFT,
      // modal
      isModalVisible,
      showModal,
      hideModal,
    };
  },
});
