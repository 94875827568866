<template>
  <div class="footer pt-10 mt-10 grid justify-items-center mb-20 w-full">
    <div class="text-white mb-10 font-sans">
      Vibing since 2021 &middot; 
      <a class="underline font-sans" href="https://twitter.com/vibesnfts" target="new">@vibesnfts</a> &middot; 
      <a class="underline font-sans" href="https://discord.gg/wYhKxE9nhz" target="new">Discord</a> &middot;
      <a class="underline font-sans" href="/terms">Terms of Service</a> &middot;
      <a class="underline font-sans" href="/privacy">Privacy Policy</a> &middot;
      <a class="underline font-sans" href="https://docs.google.com/forms/d/e/1FAIpQLSfmJFz6UfwC5dg3pwqY4JyZbYSuIBcZj3PBmReakPYv_U7FTA/viewform?usp=sf_link">Contact</a> &middot;
      Powered by <a class="underline font-sans" href="https://solana.com/" target="new">Solana</a>
    </div>
    <div class="text-white">
      <div class="italic font-sans text-center">
        “The vibes are all around us, for the taking.”
      </div>
      <div class="font-sans text-center">
        —<a class="underline font-sans" href="https://www.newyorker.com/culture/cultural-comment/tiktok-and-the-vibes-revival" target="new">Kyle Chayka</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  border-top: 1px solid white;
  height: 100px;
}
</style>
