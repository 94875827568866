
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import NavButton from '@/components/NavButton.vue';

export default defineComponent({
  components: { NavButton },
  setup() {
    const route = useRoute();
    const path = computed(() => route.path);
    const home = "/"
    const menu = [
      { name: 'VIBECHAIN', url: '/' },
      { name: 'RARE VIBES', url: 'https://rare.vibrations.fm' },
      { name: 'FAQ', url: '/faq' },
      { name: 'DISCORD', url: 'https://discord.gg/wYhKxE9nhz' }
    ];

    return {
      menu,
      path,
      home
    };
  },
});
