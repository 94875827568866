
import { defineComponent } from 'vue';
import ConfigPane from '@/components/ConfigPane.vue';
import NFTMiscForm from '@/components/NFTMiscForm.vue';

export default defineComponent({
  components: { NFTMiscForm, ConfigPane },
  setup() {
    return {};
  },
});
