import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative h-full min-h-screen" }
const _hoisted_2 = { class: "pt-10 px-10 flex justify-center align-middle" }
const _hoisted_3 = { class: "p-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheLogo = _resolveComponent("TheLogo")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_TheFooter = _resolveComponent("TheFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TheLogo)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_TheFooter)
  ]))
}