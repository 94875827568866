
import { defineComponent } from 'vue';
import VueJsonPretty from 'vue-json-pretty';
import useCopy from '@/composables/copy';
import { stringifyPubkeysAndBNsInObject } from '@/common/helpers/util';

export default defineComponent({
  components: { VueJsonPretty },
  setup() {
    const data = JSON.parse(`
    {
      "name": "new_name",
      "symbol": "new_symbol",
      "uri": "https://gateway.pinata.cloud/ipfs/QmNQh8noRHn7e7zt9oYNfGWuxHgKWkNPducMZs1SiZaYw4",
      "sellerFeeBasisPoints": 10,
      "creators": [
        {
          "address": "75ErM1QcGjHiPMX7oLsf9meQdGSUs4ZrwS2X8tBpsZhA",
          "verified": false,
          "share": 50
        },
        {
          "address": "AGsJu1jZmFcVDPdm6bbaP54S3sMEinxmdiYWhaBBDNVX",
          "verified": false,
          "share": 50
        }
      ]
    }
    `);

    // --------------------------------------- clipboard
    const { copyText, doCopyJSON } = useCopy();

    return {
      data,
      copyText,
      doCopyJSON,
      stringifyPubkeysAndBNsInObject,
    };
  },
});
