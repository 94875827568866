
import { defineComponent } from 'vue';
import ConfigPane from '@/components/ConfigPane.vue';
import NFTMintForm from '@/components/NFTMintForm.vue';
import TheNavBar from '@/components/TheNavBar.vue';

export default defineComponent({
  components: { NFTMintForm, ConfigPane, TheNavBar },
  setup() {
    return {};
  },
});
