
import { defineComponent, ref, onMounted } from 'vue';
import ConfigPane from '@/components/ConfigPane.vue';
import NFTMintForm from '@/components/NFTMintForm.vue';
import axios from 'axios';

export default defineComponent({
  components: { NFTMintForm, ConfigPane },
  setup() {    
  },
  data() {
    return {
      vibes: null
    }
  },
  mounted() {
    axios.get('https://api.vibrations.fm/vibes').then(res => {
      this.vibes = res.data;
    });  
  }
});
